.custom-list {
    list-style-type: none; /* Removes bullets */
    padding-left: 1.5rem;  /* Adds indentation */
    margin-bottom: 1.5rem; /* Adds space below the list */
  }
  
  .custom-list-item {
    font-weight: bold; /* Emphasizes the key points */
    margin-bottom: 0.5rem; /* Adds space between each item */
    color: #333; /* Ensures good contrast */
  }
  
  .custom-list-item span {
    font-weight: normal; /* Makes the rest of the text less prominent */
  }
  
  .custom-list-dark {
    list-style-type: none; /* Removes bullets */
    padding-left: 1.5rem;  /* Adds indentation */
    margin-bottom: 1.5rem; /* Adds space below the list */
    color: #fff; /* Sets white text for dark backgrounds */
  }
  
  .custom-list-item-dark {
    font-weight: bold; /* Emphasizes the key points */
    margin-bottom: 0.5rem; /* Adds space between each item */
    color: #fff; /* White for primary text */
  }
  
  .custom-list-item-dark span {
    font-weight: normal; /* Normal weight for secondary text */
    color: #ccc; /* Use a softer white/gray for secondary text */
  }

  /* Gradient text utility */
.gradient-text {
  background: linear-gradient(45deg, #6366f1, #8b5cf6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Glassmorphism effect */
.glass-card {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Floating animation */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.animate-float {
  animation: float 8s ease-in-out infinite;
}

/* Gradient border effect */
.gradient-border {
  position: relative;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(45deg, #6366f1, #8b5cf6) border-box;
  border: 2px solid transparent;
}

/* Section padding consistency */
.section-padding {
  padding: 8rem 1.5rem;
}

/* Card hover effects */
.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
  